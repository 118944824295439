import React, { useContext } from 'react';
import { Box, Button, Divider, List, Typography } from '@mui/material';
import { OfflineContext } from '@aldridge/aldg-helpers';
import NavItem from './NavItem';
import SidebarData from '../../_GlobalComponents/Menu/SideBarData';
import { UserContext } from '../../providers/UserProvider';
import * as serviceWorker from '../../serviceWorkerRegistration';
import SubmitHelpDeskTicket from '../../utils/SubmitHelpDeskTicket';
// import { config } from '../../firebase';

const DashboardSidebar = (props) => {
  const user = useContext(UserContext) || {};
  const online = useContext(OfflineContext);
  // eslint-disable-next-line react/prop-types
  const { setApplicationDrawer } = props;
  const fRefreshApplication = () => {
    serviceWorker.unregister().then(() => {
      localStorage.removeItem('DJR-KEY');
      window.location.reload();
    });
  };
  const data = JSON.parse(JSON.stringify(SidebarData));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Typography color='textPrimary' variant='h5'>
          {user.name}
        </Typography>
        <Typography color='textSecondary' variant='body2'>
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2, pb: 1, pt: 0 }}>
        <List>
          {data.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              onClick={setApplicationDrawer}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          m: 1,
          p: 1
        }}
      >
        <Typography align='center' gutterBottom variant='h4'>
          Need Help?
        </Typography>
        <div style={{ marginTop: '-8px', paddingBottom: '4px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 1
            }}
          >
            {online ? (
              <Button
                color='primary'
                variant='contained'
                style={{ whiteSpace: 'nowrap' }}
                onClick={fRefreshApplication}
              >
                Force Refresh Application
              </Button>
            ) : (
              <div style={{ color: 'red', fontWeight: 'bold' }}>
                You must be online to force refresh your application.
              </div>
            )}
          </Box>
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 1
          }}
        >
          <Typography align='center' variant='body2'>
            Please contact Customer Support
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 1
          }}
        >
          <SubmitHelpDeskTicket />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardSidebar;
