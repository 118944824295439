/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Grid, Button, InputAdornment } from '@mui/material';
import { InputNumber } from '@aldridge/aldg-data-components';
import { signInWithGoogle, signInWithPhone, verifyCode } from '../firebase';
import GoogleButton from '../_GlobalComponents/GoogleButton';

const SignIn = () => {
  const [error] = useState(null);
  const [phoneAttempt, setPhoneAttempt] = useState('');
  const [, setVerifyAttempt] = useState('');
  const signInWithPhoneCallback = (e) => {
    setPhoneAttempt(e);
  };
  const verifyCodeCallback = (e) => {
    setVerifyAttempt(e);
  };
  return (
    <Grid container justifyContent='center'>
      <h1 align='center'>Sign In To Use the Application</h1>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <GoogleButton onClick={signInWithGoogle} mode='dark' />
      </Grid>
    </Grid>
  );
};
export default SignIn;
