import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid, Paper, Button } from '@mui/material';
import {
  InputText,
  InputRadio,
  InputAutocomplete,
  InputMask
} from '@aldridge/aldg-data-components';
import * as yup from 'yup';

const RequestorInformation = (props) => {
  const { record, shouldBeDisabled, onChange, disabledTheme, schema } = props;
  const [addContact, setAddContact] = useState(false);

  const showAddAdditionalButton = () =>
    shouldBeDisabled ? null : (
      <Grid item xs={12} justifyContent='center' sx={{ textAlign: 'right' }}>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => setAddContact(!addContact)}
        >
          {addContact
            ? 'No Need for Additional Contact'
            : 'Add Additional Contact Info'}
        </Button>
      </Grid>
    );
  return (
    <Paper>
      <Card>
        <CardContent>
          <Grid container className='aldg-rootFormBox'>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                textDecoration: 'underline',
                fontWeight: 'bold'
              }}
            >
              Requestor Information
            </Grid>
            <Grid item xs={9}>
              <InputText
                label='Requestor Name'
                name='RequestorName'
                onChange={onChange}
                value={record.data.RequestorName || ''}
                disabled={shouldBeDisabled}
                customTheme={disabledTheme}
                required
                error={
                  !yup
                    .reach(schema, 'data.RequestorName')
                    .isValidSync(record.data.RequestorName)
                }
              />
            </Grid>
            <Grid item xs={3}>
            <InputRadio
              radios={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' }
              ]}
              FormControlLabelProps={{
                label: 'Aldridge Employee?',
              }}
              RadioGroupProps={{
                name: 'AldridgeEmployee',
                row: true,
                disabled: shouldBeDisabled,
                onChange,
                value: record.data.AldridgeEmployee || '',
              }}
              customTheme={disabledTheme}
            />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMask
                label='Phone'
                name='RequestorPhone'
                onChange={onChange}
                value={record.data.RequestorPhone || ''}
                disabled={shouldBeDisabled}
                customTheme={disabledTheme}
                required
                maskProps={{
                  mask: /(\d{0,3})(\d{0,3})(\d{0,4})/,
                  delim: '-'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputText
                label='Email'
                name='RequestorEmail'
                onChange={onChange}
                value={record.data.RequestorEmail || ''}
                required
                disabled={shouldBeDisabled}
                customTheme={disabledTheme}
                error={
                  !yup
                    .reach(schema, 'data.RequestorEmail')
                    .isValidSync(record.data.RequestorEmail)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputText
                label='Title'
                name='RequestorTitle'
                onChange={onChange}
                value={record.data.RequestorTitle || ''}
                disabled={shouldBeDisabled}
                customTheme={disabledTheme}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputAutocomplete
                name='RequestorDivision'
                value={record.data.RequestorDivision || []}
                label='Division'
                onChange={onChange}
                disabled={shouldBeDisabled}
                hidden={!(record.data.AldridgeEmployee === 'Yes')}
                customTheme={disabledTheme}
                firestoreOptions={{
                  valueKey: 'id',
                  collection: 'ENT-Divisions',
                  wheree: [['Active', '==', 'True']],
                  orderBy: 'id',
                  method: 'onSnapshot'
                }}
                optionDisplay={(opts) => opts?.DivisionName}
              />
            </Grid>
            {record.data.AldridgeEmployee === 'Yes' ? (
              <>
                <Grid item xs={3}>
                <InputRadio
                  radios={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' }
                  ]}
                  FormControlLabelProps={{
                    label: 'Is a Job Being Charged for this Donation?',
                  }}
                  RadioGroupProps={{
                    name: 'JobCharge',
                    row: true,
                    disabled: shouldBeDisabled,
                    onChange,
                    value: record.data.JobCharge || '',
                  }}
                  customTheme={disabledTheme}
                />
                </Grid>
                <Grid item xs={9}>
                  <InputAutocomplete
                    name='JobNumber'
                    value={record.data.JobNumber || []}
                    label='Job Number'
                    onChange={onChange}
                    disabled={shouldBeDisabled}
                    hidden={!(record.data.JobCharge === 'Yes')}
                    customTheme={disabledTheme}
                    firestoreOptions={{
                      valueKey: 'jctdscid',
                      collection: 'ENT-Jobs',
                      where: [
                        ['JobStatus', '==', 'Open'],
                        [
                          'Division',
                          '==',
                          record.data?.RequestorDivision[0] || ''
                        ]
                      ],
                      orderBy: 'JobNumber',
                      method: 'onSnapshot'
                    }}
                    optionDisplay={(opts) =>
                      `${opts?.JobNumber}.${opts?.SubJobNumber} - ${opts?.JobDescription}`
                    }
                  />
                </Grid>
              </>
            ) : null}

            {showAddAdditionalButton()}
            {addContact ? (
              <>
                <Grid item xs={12}>
                  <InputText
                    label='Additional Contact Name'
                    name='AdditionalContactName'
                    onChange={onChange}
                    value={record.data.AdditionalContactName || ''}
                    disabled={shouldBeDisabled}
                    customTheme={disabledTheme}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    label='Phone'
                    name='AdditionalContactPhone'
                    onChange={onChange}
                    value={record.data.AdditionalContactPhone || ''}
                    disabled={shouldBeDisabled}
                    customTheme={disabledTheme}
                    maskProps={{
                      mask: /(\d{0,3})(\d{0,3})(\d{0,4})/,
                      delim: '-'
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputText
                    label='Email'
                    name='AdditionalContactEmail'
                    onChange={onChange}
                    value={record.data.AdditionalContactEmail || ''}
                    disabled={shouldBeDisabled}
                    customTheme={disabledTheme}
                  />
                </Grid>{' '}
              </>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

RequestorInformation.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  shouldBeDisabled: PropTypes.bool,
  disabledTheme: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  schema: PropTypes.objectOf(PropTypes.any).isRequired
};
RequestorInformation.defaultProps = {
  record: {},
  shouldBeDisabled: false,
  disabledTheme: {},
  onChange: () => {}
};

export default RequestorInformation;
