import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { Drawer, Button, useMediaQuery, useTheme } from '@mui/material';
import { faCopyright } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalBox, useModalState } from '@aldridge/aldg-confirm';
import { useSetRecoilState } from 'recoil';
import { UserContext } from '../providers/UserProvider';
import './footer.css';
import { _ShowChangelogModal } from '../_Recoil/atoms';

const ConfidentialityModal = () => {
  const [modal1, setModalOpen] = useModalState({
    open: false,
    title: 'CONFIDENTIALITY NOTICE:',
    message:
      'Unless otherwise indicated or obvious from the nature of the application, the information contained in this application is attorney privileged and confidential information intended for the use of authorized individuals or entities only. If the viewer of this content is not correctly authorized, you are hereby notified that any dissemination, distribution or copying of this content is strictly prohibited.',
    record: 'goodbyehello',
    buttons: [
      {
        text: 'Close',
        onClick: (record, { close }) => {
          close();
        }
      }
    ],
    onClose: ({ close }) => {
      close();
    }
  });
  return (
    <>
      <ModalBox modal={modal1} />
      <button
        type='button'
        className='button'
        onClick={() => setModalOpen(true)}
      >
        <i>Click here to review the confidentiality statement.</i>
      </button>
    </>
  );
};

const Footer = (props) => {
  const { version, copy } = props;
  const [footerOpen, setFooterOpen] = useState(false);
  const [opening, setOpening] = useState(0);
  const [closing, setClosing] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const matches2 = useMediaQuery(theme.breakpoints.down('sm'));
  const SetShowChangelogModal = useSetRecoilState(_ShowChangelogModal);
  const user = useContext(UserContext);

  const onClick = () => {
    setFooterOpen(!footerOpen);
    if (footerOpen && (!matches || user)) setOpening(1);
    else if (!matches || user) setClosing(1);
  };
  const findBottom = () => {
    if (!footerOpen && (!matches || user)) return '0px';
    return '60px';
  };

  const onLinkClick = () => {
    localStorage.removeItem('ChangelogShown');
    SetShowChangelogModal(false);
  };

  return (
    <>
      {user ? (
        <Button
          className='icon'
          disableRipple
          sx={{
            position: 'fixed',
            bottom: findBottom(),
            right: matches2 ? '5px' : '15px',
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
            color: 'black'
          }}
          onClick={onClick}
          onAnimationEnd={() => {
            setOpening(0);
            setClosing(0);
          }}
          opening={opening}
          closing={closing}
        >
          {footerOpen ? (
            <FontAwesomeIcon
              justifycontent='right'
              sx={{ color: 'black' }}
              icon={faCopyright}
            />
          ) : (
            <FontAwesomeIcon
              sx={{ color: 'black' }}
              justifycontent='right'
              icon={faCopyright}
            />
          )}
        </Button>
      ) : null}

      <Drawer
        variant='persistent'
        anchor='bottom'
        open={footerOpen}
        onClose={() => setFooterOpen(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            backgroundColor: '#343a40',
            padding: '3px',
            color: '#fff',
            zIndex: '2500'
          }}
        >
          <div>Copyright &copy; {copy}</div>
          <ConfidentialityModal />
          <div>
            v {version}
            <button type='button' className='button' onClick={onLinkClick}>
              {' '}
              <u> Update Notes</u>
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

Footer.propTypes = {
  version: PropTypes.string,
  copy: PropTypes.string
};
Footer.defaultProps = {
  version: '0.0.0',
  copy: 'Aldridge, All Rights Reserved'
};

export default Footer;
