/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import {
  deleteDoc,
  onSnapshot,
  query,
  collection,
  doc,
  orderBy,
  getDocs,
  where,
  getDoc,
  setDoc
} from 'firebase/firestore';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import dayjs from 'dayjs';
import { httpsCallable } from 'firebase/functions';
import { UserContext } from '../../providers/UserProvider';
import { firestore, functions } from '../../firebase';
import { icons } from '../../theme';
import { NewDonationRequestEmail } from './ContributionEmail';

const DonationsFormList = (props) => {
  const { history, gridAtom, setGridAtom } = props;
  const [donations, setDonations] = useState([]);
  const [rows, setRows] = useState(donations);
  const [dates, setDates] = useState([]);
  const [selection, setSelection] = useState([]);
  const user = useContext(UserContext);

  const GridApiRef = useGridApiRef();

  useEffect(() => {
    let mounted = true;
    if (user) {
      const q = query(
        collection(firestore, 'Donations'),
        // orderBy('data.DonationNumber', 'desc'),
        orderBy('data.RequestedDate', 'desc')
      );
      onSnapshot(q, (res) => {
        const allDonations = [];
        res.forEach((d) => allDonations.push(d.data()));
        if (mounted) setDonations(allDonations);
      });
    }
    // eslint-disable-next-line consistent-return
    return () => (mounted = false);
  }, [user]);

  useEffect(() => {
    let mounted = true;
    if (mounted) setRows(donations);
    return () => (mounted = false);
  }, [donations]);

  const getFlex = (f) => f;
  const getWidth = () => null;

  const handleSelect = (params) => {
    history.push(`/admin/Donations/${params.id}`);
  };
  const addNew = () => {
    history.push(`/admin/Donations/new`);
  };
  const deleteRecords = () => {
    selection.forEach((s) => {
      deleteDoc(doc(firestore, 'Donations', s));
    });
    setSelection([]);
  };
  const columns = [
    {
      field: 'RequestedDate',
      headerName: 'Requested Date',
      valueGetter: (params) => new Date(dayjs(params.row.data.RequestedDate)),
      flex: getFlex(15),
      width: getWidth(300),
      type: 'date'
    },
    {
      field: 'Status',
      headerName: 'Status',
      valueGetter: (params) => params.row.data.Status,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'DonationNumber',
      headerName: 'Donation Number',
      valueGetter: (params) => params.row.data.DonationNumber,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'OrganizationName',
      headerName: 'Organization Name',
      valueGetter: (params) => params.row.data.OrganizationName,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'ApplicableCategory',
      headerName: 'Applicable Category',
      valueGetter: (params) => params.row.data.DonationCategory,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'RequestorName',
      headerName: 'Requestor Name',
      valueGetter: (params) => params.row.data.RequestorName,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'ApprovedAmount',
      headerName: 'Approved Amount',
      valueGetter: (params) =>
        Number.isNaN(parseFloat(params.row.data.ApprovedAmount))
          ? null
          : `$${parseFloat(params.row.data.ApprovedAmount).toLocaleString(
              undefined,
              { maximumFractionDigits: 2 }
            )}`,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];
  const iconClasses = icons();
  const getFYDates = () => {
    const getFYYear = () => {
      const month = dayjs().month();
      const year = dayjs().year();
      const yearOffset = Math.floor((month - (3 % 12 || 12)) / 12) + 1;
      return yearOffset + year;
    };
    const FYYear = getFYYear();

    const startDate = dayjs()
      .month(3)
      .date(1)
      .year(FYYear - 1)
      .format('YYYY-MM-DD');
    const endDate = dayjs().month(2).date(31).year(FYYear).format('YYYY-MM-DD');
    setDates([startDate, endDate]);
  };
  useEffect(() => {
    getFYDates();
  }, []);
  useEffect(() => {
    if (dates.length > 0) {
      GridApiRef.current.setFilterModel({
        items: [
          {
            field: 'RequestedDate',
            operator: 'between',
            value: dates
          }
        ]
      });
    }
  }, [dates]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: '10px 0',
          justifyContent: 'flex-end'
        }}
      >
        <div
          className={`${iconClasses.container} ${iconClasses.redContainer}`}
          style={{ margin: '0px 12px' }}
        >
          <Button
            onClick={deleteRecords}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon
              icon={faTrash}
              className={iconClasses.icon}
              title='Delete Selected Records'
            />
          </Button>
        </div>
        <div
          className={`${iconClasses.container} ${iconClasses.addContainer}`}
          style={{ marginRight: '0px', marginLeft: '12px' }}
        >
          <Button
            onClick={addNew}
            disableRipple
            className={iconClasses.buttonWrapper}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className={iconClasses.icon}
              onClick={addNew}
              title='Add New Request'
            />
          </Button>
        </div>
      </div>
      <div style={{ height: '100%', width: '100%' }}>
        <AldgDataGrid
          autoHeight
          rows={rows}
          columns={columns}
          apiRef={GridApiRef}
          pagination
          gridState={{ gridAtom, setGridAtom }}
          ToolbarProps = {{ 
            showColumns:false,
            showDensity:false
          }}
          showDownloads
          onRowClick={handleSelect}
        />
      </div>
    </>
  );
};

DonationsFormList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  gridAtom: PropTypes.object,
  setGridAtom: PropTypes.func
};

DonationsFormList.defaultProps = {
  history: {
    push: () => {}
  },
  gridAtom: undefined,
  setGridAtom: undefined
};

export default DonationsFormList;
