import React from 'react';
import LogoImage from './Logo.png';
import Picture1 from './Picture1.jpg';
import Picture2 from './Picture2.jpg';
import Picture3 from './Picture3.jpg';
import Picture4 from './Picture4.jpg';
import Picture5 from './Picture5.jpg';
import DividerImg from './Picture7.png';

export const Logo = () => (
  <img
    alt='Logo'
    src={LogoImage}
    style={{ borderRadius: '2px', width: '250px' }}
  />
);
export const Pic1 = () => (
  <img
    alt='Pic1'
    src={`${Picture1}?w=248&fit=crop&auto=format`}
    loading='lazy'
    srcSet={`${Picture1}?w=248&fit=crop&auto=format&dpr=2 2x`}
    height='250px'
  />
);
export const Pic2 = () => (
  <img
    alt='Pic2'
    src={`${Picture2}?w=248&fit=crop&auto=format`}
    loading='lazy'
    srcSet={`${Picture2}?w=248&fit=crop&auto=format&dpr=2 2x`}
    height='250px'
  />
);
export const Pic3 = () => (
  <img
    alt='Pic3'
    src={`${Picture3}?w=248&fit=crop&auto=format`}
    loading='lazy'
    srcSet={`${Picture3}?w=248&fit=crop&auto=format&dpr=2 2x`}
    height='250px'
  />
);
export const Pic4 = () => (
  <img
    alt='Pic4'
    src={`${Picture4}?w=248&fit=crop&auto=format`}
    loading='lazy'
    srcSet={`${Picture4}?w=248&fit=crop&auto=format&dpr=2 2x`}
    height='250px'
  />
);
export const Pic5 = () => (
  <img
    alt='Pic5'
    src={`${Picture5}?w=248&fit=crop&auto=format`}
    loading='lazy'
    srcSet={`${Picture5}?w=248&fit=crop&auto=format&dpr=2 2x`}
    height='250px'
  />
);
export const Divider = () => (
  <img
    alt='Pic6'
    src={`${DividerImg}`}
    style={{ width: '955px', height: '4px' }}
  />
);
