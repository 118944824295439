/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Grid, Button, InputAdornment } from '@mui/material';
import { InputNumber } from '@aldridge/aldg-data-components';
import {
  signInWithGoogle,
  signInWithPhone,
  signOut,
  verifyCode
} from '../firebase';
import GoogleButton from '../_GlobalComponents/GoogleButton';

const SignOut = () => {
  const [error] = useState(null);
  const [phoneAttempt, setPhoneAttempt] = useState('');
  const [, setVerifyAttempt] = useState('');

  useEffect(() => {
    signOut();
  }, []);
  return (
    <Grid container justifyContent='center'>
      <h1 align='center'>Signing Out...</h1>
    </Grid>
  );
};
export default SignOut;
