import React, { useContext, useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import {
  AppBar,
  Drawer,
  Toolbar,
  useMediaQuery,
  useTheme
  // Button
} from '@mui/material';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../components/Logo/Logo';
import { UserContext } from '../providers/UserProvider';
import DashboardSidebar from '../components/dashboard/DashboardSidebar';
// import IndexedDbWrapper from '../utils/indexedDbWrapper';
// import { config } from '../firebase';
import { _HeaderTitle } from '../_Recoil/atoms';

const Header = (props) => {
  const user = useContext(UserContext);
  const { appTitle } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const [drawer, setDrawer] = useState(false);
  const HeaderTitle = useRecoilValue(_HeaderTitle);

  return (
    <>
      <AppBar className='header' position='relative'>
        <Toolbar
          className='navbar'
          style={{ paddingLeft: '0px', paddingRight: '4px' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '5px'
            }}
          >
            {user && !matches && user && !user.payroll ? (
              <FontAwesomeIcon
                icon={faBars}
                style={{ margin: '12px', cursor: 'pointer' }}
                onClick={() => {
                  setDrawer(true);
                }}
              />
            ) : null}
            <Logo />
            <span
              style={{
                paddingLeft: '5px',
                color: 'white',
                fontSize: '1.25rem'
              }}
            >
              {HeaderTitle || appTitle}
            </span>
          </div>
          {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {!user ? <RouterLink to='SignIn' style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', paddingLeft: matches ? '12px' : '0px' }}><Button variant='contained'>Login</Button></RouterLink> : null}
            <IndexedDbWrapper projectId={config.projectId} />
          </div> */}
        </Toolbar>
        <div className='brandContainer'>
          <div className='brandBar' />
          <a href='https://www.aldridgegroup.com/'>aldridgegroup.com</a>
        </div>
      </AppBar>
      <Drawer
        anchor='left'
        onClose={() => setDrawer(false)}
        open={drawer}
        variant='temporary'
        PaperProps={{
          sx: {
            width: 256
          }
        }}
      >
        <DashboardSidebar setApplicationDrawer={() => setDrawer(false)} />
      </Drawer>
    </>
  );
};
Header.propTypes = {
  appTitle: PropTypes.string
};
Header.defaultProps = {
  appTitle: ''
};

export default Header;
