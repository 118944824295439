import { Grid, Modal, Paper, colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { blueGrey, green } from '@mui/material/colors';
import shadows from './shadows';
import typography from './typography';

const icons = makeStyles(
  (theme) => ({
    container: {
      margin: '0 12px',
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      width: '40px',
      height: '40px',
      padding: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      backgroundColor: theme.palette.grey[400],
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[500],
        boxShadow:
          '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
      }
    },
    addContainer: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      }
    },
    exportContainer: {
      backgroundColor: blueGrey[400],
      '&:hover': {
        backgroundColor: blueGrey[600]
      }
    },
    saveContainer: {
      backgroundColor: green[400],
      '&:hover': {
        backgroundColor: green[600]
      }
    },
    trashContainer: {
      borderRadius: '30px',

      backgroundColor: '#ffffff',
      '&:hover': {
        backgroundColor: '#ededed'
      }
    },
    redContainer: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    blueContainer: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      }
    },
    greenContainer: {
      backgroundColor: green[400],
      '&:hover': {
        backgroundColor: green[600]
      }
    },
    icon: {
      color: 'white',
      fontSize: '1.5rem'
    },
    trashIcon: {
      color: 'black',
      fontSize: '1.5rem'
    },
    buttonWrapper: {
      backgroundColor: 'transparent',
      padding: '0px',
      minWidth: '40px',
      maxWidth: '40px',
      minHeight: '40px',
      maxHeight: '40px'
    }
  }),
  { index: 1 }
);

const basicStyles = makeStyles(
  (theme) => ({
    container: {
      display: 'grid',
      gridGap: theme.spacing(3)
    },
    icon: {
      fontSize: '2rem',
      transform: 'scale(calc(5/6))'
    },
    simplePaper: {
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(1)
    },
    simplePrimaryPaper: {
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(3)
    },
    paperWMargin: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      margin: '0px 2px'
    },
    timePaper: {
      zIndex: 1,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
      padding: theme.spacing(1, 2, 2)
    },
    tableHeader: {
      fontWeight: 'bold',
      paddingBottom: '0px',
      padding: '0px 0px 0px 16px',
      [theme.breakpoints.down('md')]: {
        padding: '4px'
      }
    },
    tableCell: {
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        padding: '4px'
      }
    },
    gridItem: {
      padding: '5px',
      flexBasis: '0%'
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: '#efefef'
      },
      '&:nth-child(odd)': {
        backgroundColor: '#fff'
      }
    },
    row1: {
      '&:nth-child(odd)': {
        backgroundColor: '#efefef'
      },
      '&:nth-child(even)': {
        backgroundColor: '#fff'
      }
    },
    button2: {
      marginRight: theme.spacing(1)
    },
    outlinedButton: {
      button: {
        justifyContent: 'flex-start',
        padding: '2px',
        display: 'flex',
        alignItems: 'center',
        '&$outlined': {
          border: '2px solid #444'
        }
      }
    },
    field: {
      padding: '8px',
      display: 'flex',
      alignItems: 'flex-end'
    }
  }),
  { index: 1 }
);

const theme = createTheme({
  palette: {
    background: {
      default: '#3b78e7',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#3b78e7'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    }
  },
  shadows,
  typography
});

const ModalBox = styled(
  Modal,
  {}
)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '2100 !important'
});

const DivPaperForModal = styled(
  'div',
  {}
)({
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.shadows[5],
  outline: 'none',
  maxHeight: '80%',
  overflow: 'auto',
  width: '70%',
  padding: theme.spacing(1, 2),
  [theme.breakpoints.down('sm')]: {
    padding: '6px 8px',
    width: '100%'
  }
});

const GridItem = styled(
  Grid,
  {}
)({
  padding: '5px',
  flexBasis: '0%'
});

const disabledTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            opacity: 1,
            WebkitTextFillColor: `rgba(0,0,0,0.7) !important`
          }
        }
      }
    }
  }
});
const SimplePrimaryPaper = styled(Paper, { theme })({
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3)
});

const accordionClass = makeStyles(
  {
    root: {
      border: '1px solid #ccc',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&$expanded': {
        border: '1px solid #2a56a7',
        margin: '8px 0',
        '& .MuiAccordionSummary-expandIcon': {
          color: 'white'
        }
      }
    },
    expanded: {}
  },
  { index: 1 }
);

const accordionSummaryClass = makeStyles(
  {
    outLinedButton: {
      justifyContent: 'flex-start',
      padding: '2px 6px',
      display: 'flex',
      alignItems: 'center',
      color: 'black',
      '&$outlined': {
        border: '2px solid #444'
      }
    },
    outlined: {},
    root: {
      backgroundColor: '#eee',
      boxShadow: 'inset 0px -19px 4px -19px #aaa',
      color: 'inherit',
      maxHeight: '56px',
      minHeight: '48px',
      '&$expanded': {
        color: 'white',
        maxHeight: '48px',
        minHeight: '48px',
        backgroundColor: 'rgb(59, 120, 231)',
        '& .MuiButtonBase-root': {
          color: 'white'
        },
        '& $outlined': {
          border: '2px solid white'
        }
      },
      editIcon: {
        color: 'black'
      },
      expanded: {}
    },
    expanded: {}
  },
  { index: 1 }
);

const accordionDetailsClass = makeStyles(
  {
    root: {
      flexDirection: 'column'
    }
  },
  { index: 1 }
);

const pdfStyles = makeStyles(() => ({
  root: {
    margin: '0px',
    width: '720px'
  },
  main: {
    padding: theme.spacing(1),
    fontWeight: 'bold',
    textAlign: 'center',
    boxShadow: '5px 3px 5px -3px rgb(87 87 87 / 20%)',
    color: theme.palette.text.primary,
    fontSize: '16px',
    overflow: 'hidden',
    height: '100%'
  },
  titleBox: {
    padding: theme.spacing(1),
    // margin: '10px',
    fontWeight: 'bold',
    textAlign: 'center',
    boxShadow: '0 3px 5px 2px rgba(87, 87, 87, .2)',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.divider,
    fontSize: '12px',
    overflow: 'hidden',
    height: '100%'
  },
  subTitleBox: {
    padding: theme.spacing(1),
    fontWeight: 'bold',
    border: '1px solid black',
    boxShadow: '0 3px 5px 2px rgba(128, 160, 173, .2)',
    textAlign: '-webkit-left',
    backgroundColor: '#d5eaf5',
    fontSize: '12px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    height: '100%',
    whiteSpace: 'pre-wrap'
  },
  subTitleBoxSecond: {
    padding: theme.spacing(1),
    fontWeight: 'bold',
    border: '1px solid black',
    boxShadow: '0 3px 5px 2px rgba(128, 160, 173, .2)',
    textAlign: '-webkit-left',
    backgroundColor: '#d5eaf5',
    fontSize: '12px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap'
  },
  fillable: {
    fontSize: '10px',
    padding: theme.spacing(1),
    fontWeight: 'bold',
    border: '1px solid black',
    boxShadow: '0 3px 5px 2px rgba(128, 160, 173, .2)',
    backgroundColor: '#ffffff',
    // margin: '12px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    textAlign: '-webkit-left',
    height: '100%',
    whiteSpace: 'pre-wrap'
  },
  signatureBox: {
    fontSize: '14px',
    padding: theme.spacing(1),
    fontWeight: 'bold',
    border: '1px solid black',
    boxShadow: '0 3px 5px 2px rgba(128, 160, 173, .2)',
    backgroundColor: '#d5eaf5',
    // margin: '10px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap'
  },
  signatureBoxSecond: {
    fontSize: '14px',
    padding: theme.spacing(1),
    fontWeight: 'bold',
    border: '1px solid black',
    boxShadow: '0 3px 5px 2px rgba(128, 160, 173, .2)',
    backgroundColor: '#ffffff',
    // margin: '10px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    textAlign: 'center',
    height: '100%',
    whiteSpace: 'pre-wrap'
  }
}));

const AldgTheme = createTheme({
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          '&.aldg-saving': {
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#efefef',
            opacity: 0.5,
            zIndex: 2500
          },
          '&.aldg-field': {
            padding: '8px',
            display: 'flex',
            alignItems: 'flex-end'
          }
        },
        item: {
          alignSelf: 'flex-end'
          // padding: '8px'
        },
        container: {
          '&.aldg-rootFormBox > .MuiGrid-item': {
            padding: '8px'
          }
          // padding: '8px'
        }
      }
    }
  }
});
export {
  theme,
  AldgTheme,
  icons,
  basicStyles,
  accordionClass,
  accordionSummaryClass,
  accordionDetailsClass,
  pdfStyles,
  disabledTheme,
  GridItem,
  ModalBox,
  DivPaperForModal,
  SimplePrimaryPaper
};
