/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Modal,
  Backdrop,
  Box,
  useTheme,
  InputAdornment
} from '@mui/material';
import { toast } from 'react-toastify';
import { doc, setDoc } from 'firebase/firestore';
import {
  InputDate,
  InputNumber,
  InputText,
  InputTextarea
} from '@aldridge/aldg-data-components';
import { GridItem } from '../../theme';
import { UserContext } from '../../providers/UserProvider';
import { firestore } from '../../firebase';

const CommitteeCommentModal = (props) => {
  const { closePopup, record, displayBoxOpen } = props;
  const user = useContext(UserContext);
  const theme = useTheme();

  const [popupTransaction, setPopupTransaction] = useState({
    CommitteeMember: user?.name || '',
    Date: new Date().toJSON().split('T')[0],
    RecommendedAmount: '',
    Comments: ''
  });

  const updateRecords = () => {
    const updatedRec = { ...record };
    const member = updatedRec.data.CommitteeReviews.filter(
      (c) => c.name === user.name
    );
    if (member.length > 0) {
      member[0].status = displayBoxOpen.status;
      member[0].Comments = popupTransaction.Comments;
      member[0].RecommendedAmount = popupTransaction.RecommendedAmount;
      setDoc(doc(firestore, 'Donations', updatedRec.id), updatedRec, {
        merge: true
      });
      toast.success(
        `${member[0].name} has marked this donation as ${member[0].status}.`
      );
    } else {
      const proxyMember = {
        name: user.name,
        email: user.email,
        status: displayBoxOpen.status,
        Comments: popupTransaction.Comments,
        RecommendedAmount: popupTransaction.RecommendedAmount,
        proxy: true
      };
      updatedRec.data.CommitteeReviews.push(proxyMember);
      setDoc(doc(firestore, 'Donations', updatedRec.id), updatedRec, {
        merge: true
      });
      toast.success(
        `${proxyMember.name} has marked this donation as ${proxyMember.status}.`
      );
    }
    setPopupTransaction({
      CommitteeMember: user.name,
      Date: new Date().toJSON().split('T')[0],
      Comments: ''
    });
    displayBoxOpen.callback();
  };

  const handleChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedTransaction = { ...popupTransaction };
      changedTransaction[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedTransaction[`${id}Display`] = displayFromTypeahead;
      }
      setPopupTransaction(changedTransaction);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const approvedReviewed = () => {
    displayBoxOpen.callback(popupTransaction, 'approved');
    setPopupTransaction({
      CommitteeMember: user.name,
      Date: new Date().toJSON().split('T')[0],
      Comments: ''
    });
  };
  const denyReviewed = () => {
    if (popupTransaction.Comments === '') {
      toast.error(
        'Please add some comments as why this request cannot continue.'
      );
    }
    displayBoxOpen.callback(popupTransaction, 'denied');
    setPopupTransaction({
      CommitteeMember: user.name,
      Date: new Date().toJSON().split('T')[0],
      Comments: ''
    });
  };
  return (
    <Modal
      open={displayBoxOpen.open}
      BackdropComponent={Backdrop}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '2100 !important'
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          backgroundColor: theme.palette.background.paper,
          border: '2px solid #000',
          boxShadow: theme.shadows[5],
          outline: 'none',
          maxHeight: '80%',
          overflow: 'auto',
          width: '70%',
          padding: theme.spacing(1, 2),
          [theme.breakpoints.down('sm')]: {
            padding: '6px 8px',
            width: '100%'
          }
        }}
      >
        <Grid container sx={{ padding: '15px' }}>
          {record.data.Status === 'Pending' ? (
            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'center',
                fontWeight: 'bold'
              }}
            >
              Please add any additional notes for the other committee members on
              why you are giving a response of: &quot;{displayBoxOpen.status}
              &quot;
            </Grid>
          ) : null}
          <GridItem item xs={12}>
            <InputText
              name='CommitteeMember'
              label='Committee Member'
              value={user?.name || ''}
              disabled
              onChange={handleChange}
            />
          </GridItem>
          <GridItem item xs={12}>
            <InputDate
              name='Date'
              type='date'
              label='Date'
              disabled
              value={popupTransaction.Date}
              onChange={handleChange}
            />
          </GridItem>
          <GridItem item xs={12}>
            <InputTextarea
              name='Comments'
              label='Comments'
              value={popupTransaction.Comments}
              onChange={handleChange}
            />
          </GridItem>
          {record.data.Status === 'Pending' ? (
            <GridItem item xs={12}>
              <InputNumber
                name='RecommendedAmount'
                label='Recommended Amount'
                value={popupTransaction.RecommendedAmount || ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  )
                }}
                onChange={handleChange}
              />
            </GridItem>
          ) : null}
          {/* check status of record or prop here  */}
          <GridItem
            item
            xs={12}
            style={{
              textAlign: 'right',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            {record.data.Status === 'Pending Review' ? (
              <>
                <Button
                  color='success'
                  variant='contained'
                  onClick={approvedReviewed}
                >
                  Approve Request
                </Button>
                &nbsp;
                <Button
                  color='warning'
                  variant='contained'
                  onClick={denyReviewed}
                >
                  Deny Request
                </Button>
                &nbsp;
                <Button color='error' variant='contained' onClick={closePopup}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={updateRecords}
                >
                  Save
                </Button>
                &nbsp;
                <Button color='error' variant='contained' onClick={closePopup}>
                  Cancel
                </Button>
              </>
            )}
          </GridItem>
        </Grid>
      </Box>
    </Modal>
  );
};

CommitteeCommentModal.propTypes = {
  closePopup: PropTypes.func,
  displayBoxOpen: PropTypes.objectOf(PropTypes.any).isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired
};
CommitteeCommentModal.defaultProps = {
  closePopup: () => {}
};
export default CommitteeCommentModal;
