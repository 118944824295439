import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Grid, ImageList, ImageListItem } from '@mui/material';
import { UserContext } from '../providers/UserProvider';
import {
  Divider,
  Logo,
  Pic1,
  Pic2,
  Pic3,
  Pic4,
  Pic5
} from '../components/landingPage/landingPageImages';

const LandingPage = (props) => {
  const { history } = props;
  const user = useContext(UserContext);

  if (user && user.admin) {
    history.push('/Donations');
  }

  return (
    <Grid container>
      <Grid item xs={12} textAlign='center'>
        <Logo />
      </Grid>
      <Grid item xs={12} textAlign='center'>
        <h1 style={{ fontFamily: `'Pacifico', cursive` }}>
          Welcome to Aldridge Gives.
        </h1>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <h3>
          If you are looking to request a donation, please click{' '}
          <Link to='/Donations/new'>here</Link> to fill out a request form.
        </h3>
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Divider />
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <span className='cursive' style={{ fontSize: '16px' }}>
          Our Mission
        </span>{' '}
        is to empower and inspire existing and prospective employees, cultivate
        a culture of genuine caring, build strong relationships with the
        communities in which we work, and truly make a difference by championing
        causes focused on education, community, health, veterans, and the
        environment.
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3} />
      <Grid item xs={6}>
        <div className='cursive' style={{ textAlign: 'center' }}>
          Where we give.
        </div>
        <div>
          <span className='cursive' style={{ fontSize: '16px' }}>
            Community:
          </span>{' '}
          Support the needs, as well as, improving the communities quality of
          life
        </div>
        <div>
          <span className='cursive' style={{ fontSize: '16px' }}>
            Health:
          </span>{' '}
          Mental health, wellness and research{' '}
        </div>
        <div>
          <span className='cursive' style={{ fontSize: '16px' }}>
            Education:
          </span>{' '}
          STEM outreach and workforce development{' '}
        </div>
        <div>
          <span className='cursive' style={{ fontSize: '16px' }}>
            Environment:
          </span>{' '}
          Civic and community improvement
        </div>
        <div>
          <span className='cursive' style={{ fontSize: '16px' }}>
            Veterans:
          </span>{' '}
          Military and first responders
        </div>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            width: 750,
            height: 650,
            overflowY: 'auto'
          }}
        >
          <ImageList variant='masonry' cols={3} gap={8}>
            <ImageListItem>
              <Pic1 />
            </ImageListItem>
            <ImageListItem>
              <Pic2 />
            </ImageListItem>
            <ImageListItem>
              <Pic4 />
            </ImageListItem>
            <ImageListItem>
              <Pic5 />
            </ImageListItem>
            <ImageListItem>
              <Pic3 />
            </ImageListItem>
          </ImageList>
        </Box>
      </Grid>
    </Grid>
  );
};

LandingPage.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};
LandingPage.defaultProps = {
  history: {}
};

export default LandingPage;
