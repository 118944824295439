/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Paper,
  InputAdornment,
  FormHelperText,
  Typography
} from '@mui/material';
import {
  InputTextarea,
  InputText,
  Files,
  InputMask
} from '@aldridge/aldg-data-components';
import { firebaseConfig } from '../../firebase';

const RequestInformation = (props) => {
  const { record, shouldBeDisabled, onChange, disabledTheme } = props;
  return (
    <Paper>
      <Card>
        <CardContent>
          <Grid container className='aldg-rootFormBox'>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                textDecoration: 'underline',
                fontWeight: 'bold'
              }}
            >
              Request
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                paddingBottom: '0px !important'
              }}
            >
              Please describe the type of support you are requesting. Examples
              include purchasing advertising, a monetary donation, team
              participation, etc.
            </Grid>
            <Grid item xs={12}>
              <InputTextarea
                label=''
                name='Description'
                multiline
                onChange={onChange}
                value={record.data.Description || ''}
                disabled={shouldBeDisabled}
                customTheme={disabledTheme}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                paddingBottom: '0px !important'
              }}
            >
              Is this request for a specific event? If so, please describe and
              include the date of the event.
            </Grid>
            <Grid item xs={12}>
              <InputTextarea
                label=''
                onChange={onChange}
                multiline
                name='SpecificRequest'
                value={record.data.SpecificRequest || ''}
                disabled={shouldBeDisabled}
                customTheme={disabledTheme}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                paddingBottom: '0px !important'
              }}
            >
              Please quantify the benefit - such as # of persons impacted, # of
              scholarships, # of jobs created, # of lives saved, # of meals
              provided, # of trees planted, etc.
            </Grid>
            <Grid item xs={12}>
              <InputTextarea
                label=''
                name='QuantifyBenefit'
                multiline
                onChange={onChange}
                value={record.data.QuantifyBenefit || ''}
                disabled={shouldBeDisabled}
                customTheme={disabledTheme}
              />
            </Grid>
            <Grid
              sx={{
                fontSize: '14px',
                fontWeight: 'bold'
              }}
              item
              xs={12}
            >
              Please identify the Date and $ value of the most recent
              contribution from Aldridge (if any).
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                label='Contribution Date (MM/YYYY)'
                name='ContributionDate'
                onChange={onChange}
                value={record.data.ContributionDate || ''}
                disabled={shouldBeDisabled}
                customTheme={disabledTheme}
                maskProps={{
                  mask: /(\d{0,2})(\d{0,4})/,
                  delim: '/'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <InputText
                label='Previous Contribution'
                name='PreviousContribution'
                onChange={onChange}
                value={record.data.PreviousContribution || ''}
                disabled={shouldBeDisabled}
                customTheme={disabledTheme}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  )
                }}
              />
              <FormHelperText>
                {Number.isNaN(parseFloat(record.data.PreviousContribution))
                  ? null
                  : `$${parseFloat(
                      record.data.PreviousContribution
                    ).toLocaleString(undefined, { maximumFractionDigits: 2 })}`}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              {record.id.length > 0 ?
              (<>
              <Typography component='div' style={{fontWeight: 'bold'}}>Any additional documents you would like to supply...</Typography>
              <Files
                label="Any additional documents you'd like to supply..."
                folder={`${record.id}/UserDocuments`}
                firebaseConfig={firebaseConfig}
                multiple
                allowAnon
                defaultOpen
              /> </>): null}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

RequestInformation.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  shouldBeDisabled: PropTypes.bool,
  disabledTheme: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func
};
RequestInformation.defaultProps = {
  record: {},
  shouldBeDisabled: false,
  disabledTheme: {},
  onChange: () => {}
};

export default RequestInformation;
