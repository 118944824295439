/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Button,
  Box,
  FormHelperText
} from '@mui/material';

const Status = (props) => {
  const { record, shouldBeDisabled, onChange, disabledTheme, user } = props;
  const formatNumber = (num) => {
    if (Number.isNaN(parseFloat(num))) {
      return '';
    }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });

    return formatter.format(num);
  };
  return (
    <>
      {user && user.admin ? (
        <Paper>
          <Card>
            <CardContent>
              <Grid container className='aldg-rootFormBox'>
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: 'center',
                    fontSize: '20px',
                    textDecoration: 'underline',
                    fontWeight: 'bold'
                  }}
                >
                  Status
                </Grid>
                <Grid
                  container
                  sx={{
                    '& .MuiGrid-item': {
                      textAlign: 'center',
                      textDecoration: 'underline',
                      fontWeight: 'bold',
                      marginBottom: '4px'
                    }
                  }}
                >
                  <Grid item xs={12}>
                    {/* <FormHelperText>
                      **Random Statuses Assigned for Demoing and Testing
                      Purposes**
                    </FormHelperText> */}
                  </Grid>
                  <Grid item xs={3}>
                    Approver
                  </Grid>
                  <Grid item xs={2}>
                    Status
                  </Grid>
                  <Grid item xs={2}>
                    Recommended Amount
                  </Grid>
                  <Grid item xs={5}>
                    Comments
                  </Grid>
                </Grid>
                {record.data.CommitteeReviews?.map((r, idx) => (
                  <Grid
                    container
                    key={r.name}
                    sx={{
                      backgroundColor: idx % 2 ? '#fff' : '#eee',
                      '& .MuiGrid-item': {
                        padding: '5px',
                        border: '1px solid #999',
                        height: '100%'
                      }
                    }}
                  >
                    <Grid item xs={3}>
                      {r.name}{' '}
                      {r.proxy
                        ? `[This user isn't on the usual approval chain]`
                        : null}
                    </Grid>
                    <Grid item xs={2}>
                      {r.status || ' '}
                    </Grid>
                    <Grid item xs={2}>
                      {formatNumber(r.RecommendedAmount) || ' '}
                    </Grid>
                    <Grid item xs={5}>
                      {r.Comments || ' '}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      ) : null}
    </>
  );
};

Status.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  shouldBeDisabled: PropTypes.bool,
  disabledTheme: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func
};
Status.defaultProps = {
  record: {},
  user: {},
  shouldBeDisabled: false,
  disabledTheme: {},
  onChange: () => {}
};

export default Status;
