import React from 'react';
import PropTypes from 'prop-types';

const GoogleButton = (props) => {
  const { mode, onClick } = props;

  return (
    <div className={(mode === 'dark') ? 'googleSignInDark' : 'googleSignInLight'} onClick={onClick} title="Google Sign In Button" aria-hidden="true" />
  );
};

GoogleButton.propTypes = {
  mode: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default GoogleButton;
