import {
  faDollarSign,
  faHome,
  faList
} from '@fortawesome/pro-regular-svg-icons';

const SidebarData = [
  {
    href: '/',
    icon: faHome,
    title: 'Home',
    dock: false
  },
  {
    href: '/admin/Donations/new',
    icon: faDollarSign,
    title: 'Donations Form',
    dock: true
  },
  {
    href: '/admin/Donations',
    icon: faList,
    title: 'Donations Form List',
    dock: true
  }
];
export default SidebarData;
