/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Backdrop,
  Alert,
  FormHelperText
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import dayjs from 'dayjs';
import { InputText, InputDate, Files } from '@aldridge/aldg-data-components';
import { toast } from 'react-toastify';
import {
  collection,
  setDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  where
} from 'firebase/firestore';
import * as yup from 'yup';
import { useRouteMatch } from 'react-router';
import { firestore, functions, config, firebaseConfig } from '../../firebase';
import Logo from '../Logo/LogoColor';
import ErrorBoundary from '../../utils/ErrorBoundary';
import { UserContext } from '../../providers/UserProvider';

import {
  SimplePrimaryPaper,
  DivPaperForModal,
  ModalBox,
  disabledTheme
} from '../../theme';
import CommitteeCommentModal from './CommitteeCommentModal';
import HeaderInformation from './HeaderInformation';
import OrganizationInformation from './OrganizationInformation';
import RequestInformation from './RequestInformation';
import RequestorInformation from './RequestorInformation';
import Status from './Status';
import Buttons from './Buttons';
import RequestorConfirmationModal from './RequestorConfirmationModal';

const DonationsForm = (props) => {
  const { history } = props;
  const user = useContext(UserContext);
  const match = useRouteMatch();
  const isPotentiallyEditOnBadReview = !user;
  const [HideForm, setHideForm] = useState(false);
  const [ConfirmRequestor, setConfirmRequestor] = useState({
    open: false,
    callback: () => {}
  });
  document.title = 'Aldridge Gives Request Form';

  const [record, setRecord] = useState({
    id: '',
    CreatedBy: user?.email || 'New Requestor',
    CreatedDate: new Date().toJSON(),
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      RequestedDate: new Date().toJSON().split('T')[0],
      OrganizationName: '',
      FEIN: '',
      Address: '',
      PurposeMission: '',
      RequestedAmount: '',
      ApprovedAmount: '',
      Website: '',
      TaxExempt: '',
      DonationCategory: '',
      OtherCategory: '',
      Description: '',
      SpecificRequest: '',
      QuantifyBenefit: '',
      ContributionDate: '',
      PreviousContribution: '',
      RequestorName: '',
      AldridgeEmployee: '',
      RequestorAddress: '',
      RequestorPhone: '',
      RequestorEmail: '',
      RequestorTitle: '',
      RequestorDivision: '',
      AdditionalContactName: '',
      AdditionalContactPhone: '',
      AdditionalContactEmail: '',
      Status: 'New',
      AdminComments: false,
      Comments: '',
      DonationNumber: '',
      CommitteeReviews: [],
      FinalDate: ''
    }
  });

  const schema = yup.object().shape({
    data: yup.object().shape({
      OrganizationName: yup.string(),
      FEIN: yup.string(),
      PurposeMission: yup.string(),
      TaxExempt: yup.boolean(),
      RequestedAmount: yup.number().positive().integer(),
      ApprovedAmount: yup.number().positive().integer(),
      Website: yup.string().url(),
      RequestorName: yup.string(),
      AldridgeEmployee: yup.string(),
      RequestorEmail: yup.string().email(),
      RequestorPhone: yup.string()
    })
  });

  const onChange = (event, name, display) => {
    console.log(record);
    try {
      let id;
      let value;
      const changedReport = { ...record };
      if (typeof name === 'object' && name !== null) {
        id = name.target.name;
        value = typeof name !== 'undefined' ? event : event.target.value;
      } else {
        id = name || event.target.name;
        value = typeof name !== 'undefined' ? event : event.target.value;
      }
      if (typeof display !== 'undefined') {
        changedReport.data[`${name}Display`] = display;
      }
      changedReport.data[id] = value;
      /*
      RequestedAmount: '',
      ApprovedAmount: '',
      */
      changedReport.ModifiedBy = user?.email || 'New Requestor';
      changedReport.ModifiedDate = new Date().toJSON();

      setRecord(changedReport);
    } catch (err) {
      // eslint-disable-next-line no-console
      toast.error(err.message, { autoClose: 8000 });
    }
  };

  const displayRequestorConfirmationModal = () => {
    const uRequestor = { ...ConfirmRequestor };
    uRequestor.open = true;
    uRequestor.callback = (obj) => {
      if (typeof obj !== 'undefined') {
        getDocs(
          query(
            collection(firestore, 'Donations'),
            where('id', '==', match.params.id),
            where('data.RequestorPhone', '==', obj.RequestorPhone),
            where('data.RequestorEmail', '==', obj.RequestorEmail)
          )
        ).then((r) => {
          if (r.empty) {
            toast.error(
              `I'm sorry, the Requestor Phone and Email did not match this record. Please try again.`
            );
          } else {
            toast.info(
              'Your request has been found. Please wait one moment...'
            );
            const rec = r.docs[0];
            setHideForm(false);
            setRecord(rec.data());
            setConfirmRequestor({ open: false, callback: () => {} });
          }
        });
      } else {
        setConfirmRequestor({ open: false, callback: () => {} });
        window.location.href = '/';
      }
    };
    setConfirmRequestor(uRequestor);
  };
  useEffect(() => {
    let mounted = true;
    if (match.params.id === 'new') {
      const docRef = doc(collection(firestore, 'Donations'));
      const uRecord = { ...record };
      uRecord.id = docRef.id;
      setRecord(uRecord);
      return null;
    }

    if (user && user.admin) {
      setHideForm(false);
      setConfirmRequestor({
        open: false,
        callback: () => {}
      });
      onSnapshot(doc(firestore, 'Donations', match.params.id), (snap) => {
        if (snap.exists()) {
          const d = snap.data();
          // if (typeof d.data.CommitteeReviews.approvers !== 'undefined') {
          //   d.data.CommitteeReviews = d.data.CommitteeReviews.approvers;
          //   setDoc(doc(firestore, 'Donations', match.params.id), d);
          // }
          if (mounted) setRecord(d);
        }
      });
    }
    if (
      typeof match.params.id !== 'undefined' &&
      (user === null || !user.admin)
    ) {
      setHideForm(true);
      displayRequestorConfirmationModal();
    }
    return () => (mounted = false);
  }, [match.params.id]);

  const shouldBeDisabled = () => {
    if (record.data.Status === 'New') return false;
    if (user === null && record.data.Status === 'Pending Review') return true;
    if (user?.admin && record.data.Status !== 'New') return true;
    if (
      isPotentiallyEditOnBadReview &&
      ['Pending Review', 'Needs Review'].indexOf(record.data.Status) > -1
    )
      return false;
    return true;
  };

  const goBack = () => {
    if (user && user.admin) {
      history.push('/admin/Donations');
    } else {
      history.push('/');
    }
  };

  const saveRecord = () => {
    const docRef = doc(collection(firestore, 'Donations'), record.id);
    setDoc(docRef, record).then(() => {
      toast.info('Record saved.');
      goBack();
    });
  };
  // document.title = 'Request for Organization Support';
  const theme = useTheme();
  return (
    <>
      <ErrorBoundary componentName='Donations Form' user={user}>
        <RequestorConfirmationModal displayBoxOpen={ConfirmRequestor} />
        <SimplePrimaryPaper variant='outlined'>
          {HideForm ? null : (
            <Grid container className='aldg-rootFormBox'>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    textAlign: 'center',
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    sx={{
                      float: 'left',
                      textAlign: 'left',
                      alignSelf: 'center'
                    }}
                  >
                    <Logo />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    className='cursive'
                    sx={{ display: 'inline - block' }}
                  >
                    <h2>{document.title}</h2>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    sx={{ float: 'right', textAlign: 'right' }}
                  >
                    <Grid container>
                      {user ? (
                        <Grid item xs={6}>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={goBack}
                          >
                            Go Back
                          </Button>
                        </Grid>
                      ) : null}
                      {user && user.admin && record.id !== '' ? (
                        <Grid item xs={6}>
                          <Button
                            variant='outlined'
                            color='success'
                            onClick={saveRecord}
                          >
                            Save
                          </Button>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <HeaderInformation
                user={user}
                record={record}
                shouldBeDisabled={shouldBeDisabled()}
                onChange={onChange}
                disabledTheme={disabledTheme}
                config={config}
              />
              {record.data.Status === 'Needs Review' ? (
                <Grid item xs={12}>
                  <Alert severity='info'>
                    <FormHelperText>
                      This Request was Denied for Review. Please make
                      modifications based on the feedback from the Review.
                    </FormHelperText>
                    {record.data.DeniedReviewNotes}
                  </Alert>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <OrganizationInformation
                  user={user}
                  record={record}
                  shouldBeDisabled={shouldBeDisabled()}
                  onChange={onChange}
                  disabledTheme={disabledTheme}
                  config={config}
                  schema={schema}
                />
              </Grid>
              <Grid item xs={12}>
                <RequestInformation
                  user={user}
                  record={record}
                  shouldBeDisabled={shouldBeDisabled()}
                  onChange={onChange}
                  disabledTheme={disabledTheme}
                  config={config}
                  schema={schema}
                />
              </Grid>
              <Grid item xs={12}>
                <RequestorInformation
                  user={user}
                  record={record}
                  shouldBeDisabled={shouldBeDisabled()}
                  onChange={onChange}
                  disabledTheme={disabledTheme}
                  config={config}
                  schema={schema}
                />
              </Grid>
              <Grid item xs={12}>
                <Buttons
                  user={user}
                  record={record}
                  setRecord={setRecord}
                  shouldBeDisabled={shouldBeDisabled()}
                  onChange={onChange}
                  disabledTheme={disabledTheme}
                  config={config}
                  history={history}
                  schema={schema}
                />
              </Grid>
              <Grid item xs={12}>
                {record.id.length > 0 ? (
                  <Files
                    title='Aldridge Documents'
                    folder={`${record.id}/AdminDocuments`}
                    firebaseConfig={firebaseConfig}
                    disabled={!(user && user.admin)}
                    allowDelete={user && user.admin}
                    multiple
                    defaultOpen
                  />
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Status
                  user={user}
                  record={record}
                  shouldBeDisabled={shouldBeDisabled()}
                  onChange={onChange}
                  disabledTheme={disabledTheme}
                  config={config}
                />
              </Grid>

              <Grid item xs={12} className='cursive'>
                Aldridge Gives
              </Grid>
            </Grid>
          )}
        </SimplePrimaryPaper>
      </ErrorBoundary>
    </>
  );
};

DonationsForm.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

export default DonationsForm;
