/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';
import Donations from './Donations';
import DonationsForm from '../components/donations/DonationsForm';
import DonationsFormList from '../components/donations/DonationsFormList';
import LandingPage from './LandingPage';
import SignIn from './SignIn';
import SignOut from './SignOut';
import NoMatch from './NoMatch';

const Application = () => {
  const user = useContext(UserContext);
  const page = () => {
    if (user && user.admin) {
      return (
        <Switch>
          <Route exact path='/' component={DonationsFormList} />
          <Route exact path='/admin' component={DonationsFormList} />
          <Route exact path='/admin/Donations' component={DonationsFormList} />
          <Route exact path='/admin/Donations/:id' component={DonationsForm} />
          <Route exact path='/admin/SignOut' component={SignOut} />
          <Route exact>
            <NoMatch />
          </Route>
        </Switch>
      );
    }
    return (
      <Switch>
        <Route exact path='/' component={LandingPage} />
        <Route exact path='/Donations/:id' component={DonationsForm} />
        <Route exact path='/admin' component={SignIn} />
        <Route exact path='/admin/Donations' component={SignIn} />
        <Route exact path='/admin/Donations/:id' component={SignIn} />
      </Switch>
    );
  };

  return (
    <>
      <div className='appBody'>{page()}</div>
    </>
  );
};
export default Application;
