/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Button,
  Box,
  FormHelperText
} from '@mui/material';
import Loader from 'react-loader-spinner';
import { httpsCallable } from 'firebase/functions';

import {
  InputText,
  InputRadio
} from '@aldridge/aldg-data-components';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import {
  collection,
  setDoc,
  doc,
  getDoc,
  onSnapshot
} from 'firebase/firestore';
import { firestore, functions } from '../../firebase';
import CommitteeCommentModal from './CommitteeCommentModal';
import {
  NewDonationRequestEmail,
  NeedsAttentionEmail,
  NewDonationPendingEmail,
  FullyExecutedEmail,
  ToSubmitterEmail
} from './ContributionEmail';

const Buttons = (props) => {
  const {
    record,
    shouldBeDisabled,
    onChange,
    disabledTheme,
    user,
    setRecord,
    history
  } = props;

  const [commentModal, setCommentModal] = useState({
    open: false,
    status: '',
    callback: () => {}
  });
  const [loading, setLoading] = useState(false);
  const sendEmail = httpsCallable(functions, 'SendEmail');

  const CreateButton = React.memo(({ color, onClick, label }) => (
    <Button
      variant='contained'
      color={color}
      onClick={(e) => {
        onClick(e);
      }}
    >
      {label}
    </Button>
  ));
  CreateButton.propTypes = {
    color: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
  };

  const saveRecord = (r, status) => {
    const docRef = doc(collection(firestore, 'Donations'), r.id);
    setDoc(docRef, r);
    setRecord(r);
    if (status === 'reviewed') {
      const uCommentModal = { ...commentModal };
      uCommentModal.open = false;
      uCommentModal.callback = () => {};
      uCommentModal.status = '';
    }
    toast.info(
      `The Donation Request status has changed to: "${r.data.Status}".`
    );
    const uCommentModal = { ...commentModal };
    uCommentModal.open = false;
    uCommentModal.callback = () => {};
    uCommentModal.status = '';
  };
  const requirementsFulfilled = () => {
    const errors = [];

    if (record.data.OrganizationName === '')
      errors.push('"Organization Name" is Required.');
    if (record.data.FEIN === '') errors.push('"FEIN" is Required.');
    if (record.data.Address === '') errors.push('"Address" is Required.');
    if (record.data.RequestedAmount === '')
      errors.push('"Requested Amount" is Required.');
    if (record.data.PurposeMission === '')
      errors.push('"The Purpose/Mission" is Required.');
    if (record.data.TaxExempt === '') errors.push('"Tax Exempt" is Required.');
    if (record.data.RequestorName === '')
      errors.push('"Requestor Name" is Required.');
    if (record.data.RequestorPhone === '')
      errors.push('"Requestor Phone" is Required.');
    if (record.data.RequestorEmail === '')
      errors.push('"Requestor Email" is Required.');
    if (record.data.AldridgeEmployee === '')
      errors.push('"Aldridge Employee" is Required.');
    return errors;
  };
  const RequestNumberSystem = async () => {
    if (
      typeof record.data.DonationNumber !== 'undefined' &&
      record.data.DonationNumber.trim() === ''
    ) {
      try {
        const date = new Date();
        const month = date.getMonth() + 1;
        const year = (date.getFullYear() + (month >= 4 ? 1 : 0)).toString();
        const DonationRequestNumber = await getDoc(
          doc(firestore, 'DonationRequestNumber', year)
        );
        const DonationNumberRecord = await (async () => {
          if (!DonationRequestNumber.exists()) {
            await setDoc(
              doc(collection(firestore, 'DonationRequestNumber'), year),
              { order: 1 },
              {
                merge: true
              }
            );
            return { order: 1 };
          }
          return DonationRequestNumber.data();
        })();
        await setDoc(
          doc(collection(firestore, 'DonationRequestNumber'), year),
          { order: DonationNumberRecord.order + 1 },
          {
            merge: true
          }
        );
        return `${year.slice(
          -2
        )}-${`00000000${DonationNumberRecord.order.toString()}`.slice(-4)}`;
      } catch (err) {
        return -99;
      }
    }
    return record.data.DonationNumber;
  };
  const ApprovalCommittee = async () => {
    if (
      typeof record.data.CommitteeReviews === 'undefined' ||
      record.data.CommitteeReviews.length === 0
    ) {
      const q = await getDoc(doc(firestore, 'Committee', 'Committee'));
      // TODO: FIX THIS AFTER DEMO
      const a = q.data().approvers.map((r) => ({
        ...r,
        status: '' // Math.round(Math.random()) === 1 ? 'Approved' : 'Denied'
      }));
      return a;
    }
    return record.data.CommitteeReviews;
  };

  const submitRequest = async () => {
    try {
      const RequirementErrors = requirementsFulfilled();
      if (RequirementErrors.length > 0) {
        toast.warn(
          `${RequirementErrors.join('\n')} Please fill out the missing fields.`
        );
        return;
      }
      const updatedRec = { ...record };
      let docRef;
      if (updatedRec.data.CommitteeReviews.length === 0) {
        updatedRec.data.Status = 'Pending Review';
        updatedRec.url = `https://aldridge-gives.aldridge.app/Donations/${updatedRec.id}`;
        const grabApprovalCommittee = await ApprovalCommittee();
        updatedRec.data.CommitteeReviews = grabApprovalCommittee;
        docRef = doc(collection(firestore, 'Donations'), updatedRec.id);
        // updatedRec.data.CommitteeReviews.forEach((reviewer) => reviewer.LastEmailSent = new Date());
      } else {
        updatedRec.data.Status = 'Pending Review';
        docRef = doc(collection(firestore, 'Donations'), updatedRec.id);
      }
      setLoading(true);
      NewDonationRequestEmail(updatedRec, sendEmail).then(() => {
        ToSubmitterEmail(updatedRec, sendEmail).then(() => {
          setDoc(docRef, updatedRec, { merge: true }).then(() => {
            setRecord(updatedRec);
            setLoading(false);
            history.push('/');
            toast.success(
              'Thank you for your letter requesting support. Our company channels requests for donations through a committee, Aldridge Gives. The committee will review and provide a decision in the next 10-14 business days and will inform you of their decision. We appreciate this opportunity to review your request.',
              { autoClose: 10000 }
            );
          });
        });
      });
    } catch (err) {
      console.log(err);
      toast.error('Error occured while submitting form.', { autoClose: 5000 });
    }
  };

  const closePopup = () => {
    const uCommentModal = { ...commentModal };
    uCommentModal.open = false;
    setCommentModal(uCommentModal);
  };
  const reviewRequest = () => {
    const uCommentModal = { ...commentModal };
    uCommentModal.open = true;
    uCommentModal.callback = async (d, status) => {
      const uRecord = { ...record };
      if (status.toLowerCase() === 'denied') {
        if (d.Comments !== '') {
          uRecord.data.DeniedReviewNotes = d.Comments;
          uRecord.data.Status = 'Needs Review';
          NeedsAttentionEmail(uRecord, sendEmail).then(() => {
            saveRecord(uRecord, 'reviewed');
            closePopup();
          });
        }
      }

      if (status.toLowerCase() === 'approved') {
        const requestNumber = await RequestNumberSystem();
        uRecord.data.DonationNumber = requestNumber;
        uRecord.data.Status = 'Pending';
        setLoading(true);
        NewDonationPendingEmail(uRecord, sendEmail).then(() => {
          setLoading(false);
          saveRecord(uRecord, 'reviewed');
          closePopup();
        });
      }
    };
    setCommentModal(uCommentModal);
  };
  const DonationStatus = (status) => {
    const uCommentModal = { ...commentModal };
    uCommentModal.open = true;
    uCommentModal.status = status;
    uCommentModal.callback = async (d) => {
      closePopup();
    };
    setCommentModal(uCommentModal);
  };

  const fullyReviewed = () => {
    const numberOfMembers = record.data.CommitteeReviews.length;
    let numberOfVotes = 0;
    for (let i = 0; i < record.data.CommitteeReviews.length; i++) {
      if (
        typeof record.data.CommitteeReviews[i].status !== 'undefined' &&
        record.data.CommitteeReviews[i].status !== ''
      )
        numberOfVotes += 1;
    }
    return (numberOfVotes / numberOfMembers) * 100 >= 50;
  };

  const FullyExecuted = (status) => {
    const aRecord = { ...record };
    aRecord.data.Status = status;
    setLoading(true);
    if (status === 'Fully Executed Approved') {
      if (aRecord.data.ApprovedAmount !== '') {
        FullyExecutedEmail(aRecord, sendEmail).then((r) => {
          saveRecord(aRecord, status);
          setLoading(false);
        });
      } else {
        setLoading(false);
        toast.error(
          'Please enter the Final Approved Amount before completing the request.'
        );
      }
    } else {
      FullyExecutedEmail(aRecord, sendEmail).then((r) => {
        saveRecord(aRecord, status);
        setLoading(false);
      });
    }
  };

  return (
    <>
      <CommitteeCommentModal
        closePopup={closePopup}
        record={record}
        displayBoxOpen={commentModal}
      />
      <Grid container className='aldg-rootFormBox'>
        {loading ? (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Loader type='TailSpin' />
          </Grid>
        ) : null}
        {['Needs Review', 'New'].indexOf(record.data.Status) > -1 &&
        !loading ? (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <CreateButton
              color='primary'
              label='Submit Request'
              onClick={submitRequest}
            />
          </Grid>
        ) : null}
        {user &&
        user.admin &&
        record.data.Status === 'Pending Review' &&
        !loading ? (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <CreateButton
              color='warning'
              label='Review Request'
              onClick={reviewRequest}
            />
          </Grid>
        ) : null}
        {user &&
        user.admin &&
        !loading &&
        fullyReviewed() &&
        [
          'elucas@aldridgegroup.com',
          'tgibbs@aldridgegroup.com',
          'jchimienti@aldridgegroup.com',
          'mschyman@aldridgegroup.com',
          'jmedeiros@aldridgegroup.com'
        ].indexOf(user.email) > -1 ? (
          <>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <CreateButton
                color='success'
                label='Fully Executed Approved'
                onClick={() => FullyExecuted('Fully Executed Approved')}
              />
            </Grid>
            <Grid item xs={6}>
              <CreateButton
                color='error'
                label='Fully Executed Denied'
                onClick={() => FullyExecuted('Fully Executed Denied')}
              />
            </Grid>
          </>
        ) : null}
        {user && user.admin && !loading ? (
          <>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <CreateButton
                color='success'
                label=' Approve Donation'
                onClick={() => DonationStatus('Approved')}
              />
            </Grid>
            <Grid item xs={6}>
              <CreateButton
                color='error'
                label=' Deny Donation'
                onClick={() => DonationStatus('Denied')}
              />
            </Grid>
          </>
        ) : null}
        {user &&
        user.admin &&
        record.data.Status === 'Pending' &&
        record.data.CommitteeReviews.filter(
          (r) => r.name === user.name && r.status !== ''
        ).length > 0 ? (
          <Grid item xs={12}>
            <FormHelperText sx={{ textAlign: 'center' }}>
              You have already made a decision on this request. You can change
              your decision by Approving/Denying the request again.
            </FormHelperText>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

Buttons.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  shouldBeDisabled: PropTypes.bool,
  disabledTheme: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  setRecord: PropTypes.func,
  history: PropTypes.objectOf(PropTypes.any)
};
Buttons.defaultProps = {
  record: {},
  user: {},
  shouldBeDisabled: false,
  disabledTheme: {},
  onChange: () => {},
  setRecord: () => {},
  history: {}
};

export default Buttons;
