import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { InputText, InputDate } from '@aldridge/aldg-data-components';

const OrganizationInformation = (props) => {
  const { record, shouldBeDisabled, onChange, disabledTheme, user } = props;
  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          sx={{
            justifyContent: 'center',
            marginTop: '-4px',
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              float: 'left',
              textAlign: 'left',
              paddingRight: '10px'
            }}
          >
            <InputText
              label='Status'
              name='Status'
              value={record.data.Status}
              disabled
              customTheme={disabledTheme}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: 'inline - block', paddingRight: '10px' }}
          >
            <InputDate
              label='Requested Date'
              name='RequestedDate'
              onChange={onChange}
              disabled={shouldBeDisabled}
              value={record.data.RequestedDate || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ float: 'right', textAlign: 'right' }}>
            {user ? (
              <InputText
                label='Donation Request Number'
                name='DonationNumber'
                value={record.data.DonationNumber || ''}
                disabled
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

OrganizationInformation.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  shouldBeDisabled: PropTypes.bool,
  disabledTheme: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func
};
OrganizationInformation.defaultProps = {
  record: {},
  user: {},
  shouldBeDisabled: false,
  disabledTheme: {},
  onChange: () => {}
};

export default OrganizationInformation;
