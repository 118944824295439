/* eslint-disable arrow-body-style */
import { toast } from 'react-toastify';
import { firebaseConfig } from '../../firebase';

const preview = (dataobj) => {
  const General = {
    RequestedDate: 'Requested Date',
    OrganizationName: 'Organization Name',
    FEIN: 'FEIN',
    Address: 'Address',
    RequestedAmount: 'Requested Amount',
    PurposeMission: 'Purpose / Mission of Organization',
    ApprovedAmount: 'Approved Amount',
    Website: 'Website',
    DonationCategory: 'Request Category'
  };
  const RequestInfo = {
    Description: 'Request Description',
    SpecificRequest: 'Specify Event',
    QuantifyBenefit: 'Request Quantification',
    ContributionDate: 'Contribution Date',
    PreviousContribution: 'Previous Contribution'
  };
  const Requestor = {
    RequestorName: 'Name',
    AldridgeEmployee: 'Aldridge Employee',
    RequestorPhone: 'Phone',
    RequestorEmail: 'Email',
    RequestorTitle: 'Title',
    RequestorDivision: 'Division',
    AdditionalContactName: 'Additional Name',
    AdditionalContactPhone: 'Additional Phone',
    AdditionalContactEmail: 'Additional Email'
  };
  // const ApproverStatus = {
  //   Status: 'Status',
  //   AdminComments: 'AdminComments',
  //   Comments: 'Comments',
  //   DonationNumber: 'Donation Number',
  //   CommitteeReviews: 'Committee Reviews'
  // };
  const SectionHeaderStyles =
    'font-size:16pt;font-weight:bold;text-align:left;color:#ffffff;background-color:#2c3f50;padding:0.5rem';
  const DataTrRowStyles = 'border-bottom: 1px solid #eee;';
  const DataTdValueStyles = 'padding: 5px;';
  const DataTdHeaderStyles =
    'font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem; min-width: 200px; max-width: 200px;border-right: 1px solid #eee;';
  const header = `
        <table style="border-spacing:0;border-collapse:collapse;padding:0;vertical-align:top;text-align:left;border:1px solid #ddd;width:100%">
          <tr>
            <td colspan="4" valign="top" style="${SectionHeaderStyles}">
              <div style="margin-left:0.000px">General Donation Information</div>
            </td>
          </tr>`;

  let content = '';
  Object.keys(General).forEach((prop) => {
    let info =
      typeof dataobj.data[prop] === 'undefined' ? '' : dataobj.data[prop];
    switch (prop) {
      case 'Address':
        info = `${dataobj.data.Address.formattedAddress || ''}`;
        break;
      case 'RequestedAmount':
        info = `${
          Number.isNaN(parseFloat(dataobj.data.RequestedAmount))
            ? ''
            : `$${parseFloat(dataobj.data.RequestedAmount).toLocaleString(
                undefined,
                { maximumFractionDigits: 2 }
              )}`
        }`;
        break;

      case 'DonationCategory':
        info =
          dataobj.data[prop].indexOf('Other') > -1
            ? info.replace('Other', `Other: "${dataobj.data.OtherCategory}"`)
            : info;
        break;
      default:
        break;
    }
    content += `
          <tr style="${DataTrRowStyles}">
            <td valign="top" style="${DataTdHeaderStyles}">${General[prop]}</td>
            <td colspan="3" style="${DataTdValueStyles}">${info}</td>
          </tr>`;
  });
  content += `<tr>
      <td colspan="4" valign="top" style="${SectionHeaderStyles}">
          <div style="margin-left:0.000px">Organization Information</div>
      </td>
      </tr>`;
  Object.keys(RequestInfo).forEach((prop) => {
    const info =
      typeof dataobj.data[prop] === 'undefined' ? '' : dataobj.data[prop];
    switch (prop) {
      default:
        break;
    }
    content += `
                <tr style="${DataTrRowStyles}">
                  <td valign="top" style="${DataTdHeaderStyles}">${RequestInfo[prop]}</td>
                  <td colspan="3" style="${DataTdValueStyles}">${info}</td>
                </tr>`;
  });
  content += `<tr>
      <td colspan="4" valign="top" style="${SectionHeaderStyles}">
          <div style="margin-left:0.000px">Requestor Info</div>
      </td>
      </tr>`;
  Object.keys(Requestor).forEach((prop) => {
    const info =
      typeof dataobj.data[prop] === 'undefined' ? '' : dataobj.data[prop];
    let skip = false;
    switch (prop) {
      case 'RequestorDivision':
        if (dataobj.data.AldridgeEmployee === 'No') {
          skip = true;
        }
        break;
      case 'AdditionalContactName':
      case 'AdditionalContactPhone':
      case 'AdditionalContactEmail':
        if (info.trim() === '') {
          skip = true;
        }
        break;
      default:
        break;
    }
    content += !skip
      ? `<tr style="${DataTrRowStyles}">
                    <td valign="top" style="${DataTdHeaderStyles}">${Requestor[prop]}</td>
                    <td colspan="3" style="${DataTdValueStyles}">${info}</td>
                  </tr>`
      : '';
  });

  const footer = `
        </table>
        <a href='${window.location.origin}/admin/Donations/${dataobj.id}'>
          <div style='font-size:12pt;font-weight:bold;color:#fff;background-color:#4285f4;text-align:center;border-radius: 0.15rem;width: fit-content;margin: 0.5rem 0;padding: 0.5rem 0.75rem;'>View in App</div>
        </a>
      `;

  const html = header + content + footer;
  return html;
};
const NewDonationRequestEmail = async (dataobj, sendEmail) =>
  new Promise((resolve, reject) => {
    const html = preview(dataobj);

    const preHTML = (() => {
      if (dataobj.data.Status === 'Pending Review')
        return `<h2>Request for ${dataobj.data.OrganizationName}</h2><p>A new request has been made. Click <a href="https://aldridge-gives.aldridge.app/admin/Donations/${dataobj.id}" target="_blank">here</a> to view the new record.</p>`;
      if (
        dataobj.data.Status === 'Fully Executed Approved' ||
        dataobj.data.Status === 'Fully Executed Denied'
      )
        return `<div>
      <p>The request ${dataobj.data.DonationNumber} for organization ${dataobj.data.OrganizationName} has been ${dataobj.data.Status}. Click </p> <a href="${dataobj.url}"> here </a> <p> to view the final report.
      </p></div>`;
      if (dataobj.data.Status === 'Pending')
        return `<h2>Request #${dataobj.data.DonationNumber} for ${dataobj.data.OrganizationName}</h2></br><p>There is a new request to be reiviewed. Click <a href=${dataobj.url}> here </a> to view the request.</p>`;
      return '';
    })();

    try {
      const toEmailList = (() => {
        // return [
        //   'tgibbs@aldridgegroup.com',
        //   'jchimienti@aldridgegroup.com',
        //   'ifrishman@aldridgegroup.com'
        // ];
        // if (true) return ['tgibbs@aldridgegroup.com'];
        if (firebaseConfig.projectId.indexOf('-dev') > -1) {
          return ['tgibbs@aldridgegroup.com'];
        }
        if (window.location.hostname === 'localhost')
          return ['tgibbs@aldridgegroup.com'];
        if (
          dataobj.CreatedBy === 'ifrishman@aldridgegroup.com' ||
          dataobj.CreatedBy === 'elucas@aldridgegroup.com' ||
          dataobj.CreatedBy === 'tgibbs@aldridgegroup.com'
        ) {
          return [dataobj.CreatedBy];
        }
        if (dataobj.data.Status === 'Pending Review')
          return ['jmedeiros@aldridgegroup.com'];
        if (dataobj.data.Status === 'Pending') {
          const e = [dataobj.CreatedBy];
          dataobj.data.CommitteeReviews.forEach((reviewer) =>
            e.push(reviewer.email)
          );
          return e.filter((a) => a.trim() !== '');
        }
        return ['tgibbs@aldridgegroup.com'];
      })();

      const subjectLine = (() => {
        return `New Request from "${dataobj.data.RequestorName}" for "${dataobj.data.OrganizationName}"`;
      })();
      sendEmail({
        body: {
          to: toEmailList,
          bcc: ['tgibbs@aldridgegroup'],
          subject: subjectLine,
          html: `${preHTML} ${html}.`
        }
      }).then(() => {
        resolve();
      });
    } catch (err) {
      reject(err.message);
      toast.error(err.message);
    }
  });
const NewDonationPendingEmail = async (dataobj, sendEmail) =>
  new Promise((resolve, reject) => {
    const html = preview(dataobj);

    const preHTML = (() =>
      `<h2>Request #${dataobj.data.DonationNumber} for ${dataobj.data.OrganizationName}</h2><p>A new request has been approved for viewing. Click <a href="https://aldridge-gives.aldridge.app/admin/Donations/${dataobj.id}" target="_blank">here</a> to view the request.</p>`)();

    try {
      const toEmailList = (() => {
        // if (true) return ['tgibbs@aldridgegroup.com'];
        // return [
        //   'tgibbs@aldridgegroup.com',
        //   'jchimienti@aldridgegroup.com',
        //   'ifrishman@aldridgegroup.com'
        // ];
        if (firebaseConfig.projectId.indexOf('-dev') > -1) {
          return ['tgibbs@aldridgegroup.com'];
        }

        if (dataobj.data.Status === 'Pending Review')
          return ['jmedeiros@aldridgegroup.com'];
        if (dataobj.data.Status === 'Pending') {
          const e = [];
          dataobj.data.CommitteeReviews.forEach((reviewer) =>
            e.push(reviewer.email)
          );
          return e.filter((a) => a.trim() !== '');
        }
        return ['tgibbs@aldridgegroup.com'];
        // return [dataobj.CreatedBy];
      })();
      const subjectLine = (() => {
        return `Pending Request from "${dataobj.data.RequestorName}" for "${dataobj.data.OrganizationName}"`;
      })();
      sendEmail({
        body: {
          to: toEmailList,
          bcc: ['tgibbs@aldridgegroup'],
          subject: subjectLine,
          html: `${preHTML} ${html}.`
        }
      }).then(() => {
        resolve();
      });
    } catch (err) {
      reject(err.message);
      toast.error(err.message);
    }
  });

const NeedsAttentionEmail = async (dataobj, sendEmail) =>
  new Promise((resolve) => {
    const subjectLine = (() =>
      `Your Request for "${dataobj.data.OrganizationName}" Needs More Attention`)();
    // const toEmailList = ['tgibbs@aldridgegroup.com'];
    const toEmailList = (() => {
      if (window.location.hostname === 'localhost')
        return ['tgibbs@aldridgegroup.com'];
      if (firebaseConfig.projectId.indexOf('-dev') > -1) {
        return ['tgibbs@aldridgegroup.com'];
      }
      return [dataobj.data.RequestorEmail];
    })();
    const html = `<p>
      <div>We have found a few things wrong with your request form. Please review the comments and make the necessary changes and re-submit your request form.</div>
      <div>Click <a href="https://aldridge-gives.aldridge.app/Donations/${dataobj.id}" target="_blank">here</a> to review the request.</div>
    </p>`;

    sendEmail({
      body: {
        to: toEmailList,
        bcc: ['tgibbs@aldridgegroup'],
        subject: subjectLine,
        html: `${html}`
      }
    }).then(() => {
      resolve();
    });
  });

const ToSubmitterEmail = async (dataobj, sendEmail) =>
  new Promise((resolve) => {
    const subjectLine = (() =>
      `Your Request for "${dataobj.data.OrganizationName}" Has Been Submitted`)();
    // const toEmailList = ['tgibbs@aldridgegroup.com'];
    const toEmailList = (() => {
      if (window.location.hostname === 'localhost')
        return ['tgibbs@aldridgegroup.com'];
      if (firebaseConfig.projectId.indexOf('-dev') > -1) {
        return ['tgibbs@aldridgegroup.com'];
      }
      return [dataobj.data.RequestorEmail];
    })();
    let html = '';
    html = `<p>
        <div>"Thank you for your letter requesting support. Our company channels requests for donations through a committee, Aldridge Gives. The committee will review and provide a decision in the next 10-14 business days and will inform you of their decision. We appreciate this opportunity to review your request."</div>
      </p>`;

    sendEmail({
      body: {
        to: toEmailList,
        bcc: ['tgibbs@aldridgegroup'],
        subject: subjectLine,
        html: `${html}`
      }
    }).then(() => {
      resolve();
    });
  });

const FullyExecutedEmail = async (dataobj, sendEmail) =>
  new Promise((resolve) => {
    const status =
      dataobj.data.Status.toLowerCase().indexOf('approved') > -1
        ? 'Approved'
        : 'Denied';
    const subjectLine = (() =>
      `Your Request for "${dataobj.data.OrganizationName}" Has Been ${status}`)();
    // const toEmailList = ['tgibbs@aldridgegroup.com'];
    const toEmailList = (() => {
      if (window.location.hostname === 'localhost')
        return ['tgibbs@aldridgegroup.com'];
      if (firebaseConfig.projectId.indexOf('-dev') > -1) {
        return ['tgibbs@aldridgegroup.com'];
      }
      return [dataobj.data.RequestorEmail];
    })();
    let html = '';
    if (status === 'Approved') {
      html = `<p>
          <div>Thank you for your well-reasoned request for support. We are pleased to make a donation to your organization for $${dataobj.data.ApprovedAmount}. An Aldridge Gives committee member will reach out to you directly to coordinate the logistics of the contribution. Our company values your efforts and we wish you all success in this good initiative.</div>
        </p>`;
    } else {
      html = `<p>
        <div>Your request for support was reviewed by the Aldridge Gives committee. We greatly admire your efforts and as much as we would like to contribute to your worthy cause, we are unable to approve your request at this time. We wish you all the best in your initiatives</div>
      </p>`;
    }

    sendEmail({
      body: {
        to: toEmailList,
        bcc: ['tgibbs@aldridgegroup'],
        subject: subjectLine,
        html: `${html}`
      }
    }).then(() => {
      resolve();
    });
  });

export {
  NewDonationRequestEmail,
  ToSubmitterEmail,
  NeedsAttentionEmail,
  NewDonationPendingEmail,
  FullyExecutedEmail,
  preview
};
