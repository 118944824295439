/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Modal, Backdrop, Box, useTheme } from '@mui/material';
import { toast } from 'react-toastify';
import { doc, setDoc } from 'firebase/firestore';
import {
  InputDate,
  InputMask,
  InputText,
  InputTextarea
} from '@aldridge/aldg-data-components';
import { GridItem } from '../../theme';
import { UserContext } from '../../providers/UserProvider';
import { firestore } from '../../firebase';

const RequestorConfirmationModal = (props) => {
  const { displayBoxOpen } = props;
  const user = useContext(UserContext);
  const theme = useTheme();

  const [popupTransaction, setPopupTransaction] = useState({
    RequestorPhone: '',
    RequestorEmail: ''
  });
  const handleChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedTransaction = { ...popupTransaction };
      changedTransaction[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedTransaction[`${id}Display`] = displayFromTypeahead;
      }
      setPopupTransaction(changedTransaction);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Modal
      open={displayBoxOpen.open}
      BackdropComponent={Backdrop}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '2100 !important'
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          backgroundColor: theme.palette.background.paper,
          border: '2px solid #000',
          boxShadow: theme.shadows[5],
          outline: 'none',
          maxHeight: '80%',
          overflow: 'auto',
          width: '70%',
          padding: theme.spacing(1, 2),
          [theme.breakpoints.down('sm')]: {
            padding: '6px 8px',
            width: '100%'
          }
        }}
      >
        <Grid container sx={{ padding: '15px' }}>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
              fontWeight: 'bold'
            }}
          >
            Please Enter the Phone Number and Email you made the inital request
            with.
          </Grid>
          <GridItem item xs={12}>
            <InputMask
              name='RequestorPhone'
              label='Phone Number'
              value={popupTransaction.RequestorPhone || ''}
              onChange={handleChange}
              maskProps={{
                mask: /(\d{0,3})(\d{0,3})(\d{0,4})/,
                delim: '-'
              }}
            />
          </GridItem>
          <GridItem item xs={12}>
            <InputText
              name='RequestorEmail'
              label='Email'
              value={popupTransaction.RequestorEmail || ''}
              onChange={handleChange}
            />
          </GridItem>
          {/* check status of record or prop here  */}
          <GridItem
            item
            xs={12}
            style={{
              textAlign: 'right',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              color='primary'
              variant='contained'
              onClick={() => displayBoxOpen.callback(popupTransaction)}
            >
              Confirm
            </Button>
            &nbsp;
            <Button
              color='error'
              variant='contained'
              onClick={() => displayBoxOpen.callback()}
            >
              Cancel
            </Button>
          </GridItem>
        </Grid>
      </Box>
    </Modal>
  );
};

RequestorConfirmationModal.propTypes = {
  displayBoxOpen: PropTypes.objectOf(PropTypes.any).isRequired
};
RequestorConfirmationModal.defaultProps = {};
export default RequestorConfirmationModal;
